import { Component,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WalletModel } from 'src/app/shared/model/wallet.model';
import { NetworkModel } from 'src/app/shared/model/network.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent {
  @Input() wallet: WalletModel; // Define Input property
  networks: NetworkModel[] = [];
  connected_network: NetworkModel[] = [];
  selected_networks: NetworkModel[] = [];
  tokenSymbol: any;
  constructor(
    private modalService: NgbModal,
  ){
    environment.network.forEach(networkGroup => {
      networkGroup.networks.forEach(network => {
        this.networks.push({
          id: network.id,
          name: network.name,
          wsProviderEndpoint: network.wsProviderEndpoint,
          net_name: network.net_name
        })
      });
    });
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }
  public openNotification: boolean = false;
  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  public openCart: boolean = false;
  toggleCart() {
    this.openCart = !this.openCart;
  }
  selectedNetwork(network: any){
    this.selected_networks = network;
  }
}
