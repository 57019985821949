// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  APIURLHost: 'http://127.0.0.1:3007',
  ChatBotAPIURL: "http://chatbot-api.xgame.live",
  ChatBotAPIWSHost: "chatbot-api.xgame.live",
  network: [
    {
      networks: [
        { 
          id: 1,
          name: 'Evolution',
          wsProviderEndpoint: 'wss://bootnodea01.xode.net/n7yoxCmcIrCF6VziCcDmYTwL8R03a/rpc',
          net_name: 'MAINNET'
        }
      ]
    },
    {
      networks: [
        { 
          id: 2,
          name: 'Genesis',
          wsProviderEndpoint: '' ,
          net_name: 'TESTNET'
        }
      ]
    },
    {
      networks: [
        {
          id: 3,
          name: 'Development',
          wsProviderEndpoint:'wss://humidefi-dev.zeeve.net/para',
          net_name: 'DEVNET'
        }
      ]
    }
  ],
  keypair: localStorage.getItem("wallet-keypair") || "",

  // secret_key
  secret_key: "^a7T&kPzL#9s@4!gF%8H"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
