import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }
  MENUITEMS: Menu[] = [
    { path: "/home", title: "About Us", icon: "about", type: "link" },
    { path: "/home", title: "Racing", icon: "tokenomics", type: "link" },
    { path: "/home", title: "Contact Us", icon: "milestone", type: "link" },
    // { path: "/blockscanner", title: "Block Scanner", icon: "blockscanner", type: "link" },
    // { path: "/smartcontract", title: "Smart Contract", icon: "tokens", type: "link" },
  ];

  ACCOUNTITEMS: Menu[] = [
    { path: "/home", title: "Sign Up", icon: "about", type: "link" },
    { path: "/home", title: "Forgot Password", icon: "tokenomics", type: "link" },
    { path: "/home", title: "Currency", icon: "milestone", type: "link" },
    { path: "/home", title: "Language", icon: "milestone", type: "link" },
    // { path: "/blockscanner", title: "Block Scanner", icon: "blockscanner", type: "link" },
    // { path: "/smartcontract", title: "Smart Contract", icon: "tokens", type: "link" },
  ];

  SUPPORTITEMS: Menu[] = [
    { path: "/home", title: "Term of Use", icon: "about", type: "link" },
    { path: "/home", title: "Privacy Policy", icon: "tokenomics", type: "link" },
    { path: "/home", title: "Cookies", icon: "milestone", type: "link" },
    { path: "/home", title: "Sitemap", icon: "milestone", type: "link" },
    // { path: "/blockscanner", title: "Block Scanner", icon: "blockscanner", type: "link" },
    // { path: "/smartcontract", title: "Smart Contract", icon: "tokens", type: "link" },
  ];

  FAQITEMS: Menu[] = [
    { path: "/home", title: "Guide", icon: "about", type: "link" },
    { path: "/home", title: "FAQ", icon: "tokenomics", type: "link" },
    { path: "/home", title: "Return Policy", icon: "milestone", type: "link" },
    { path: "/home", title: "Help Center", icon: "milestone", type: "link" },
    // { path: "/blockscanner", title: "Block Scanner", icon: "blockscanner", type: "link" },
    // { path: "/smartcontract", title: "Smart Contract", icon: "tokens", type: "link" },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  account_items = new BehaviorSubject<Menu[]>(this.ACCOUNTITEMS);
  support_items = new BehaviorSubject<Menu[]>(this.SUPPORTITEMS);
  faq_items = new BehaviorSubject<Menu[]>(this.FAQITEMS);
}
