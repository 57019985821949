<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <app-livebar></app-livebar>
  <div class="header-wrapper row m-0">
    <!-- <app-search></app-search> -->
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/logos/royal_race_logo.webp" alt=""/> 
          <!-- <span style="font-size: 20px;"> Xode</span> -->
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()" style="color: white;">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <!-- <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div> -->
    <div class="nav-right col-xxl-7 col-xl-8 col-md-7 col-12 pull-right right-header p-0">
      <app-sidebar class="show-header"></app-sidebar>
      <ul class="nav-menus" style="padding-left: 25px;">
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li> -->
        <!-- <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <!-- <li class="onhover-dropdown" >
          <app-network [wallet]="wallet"></app-network>
        </li>
        <li class="profile-nav onhover-dropdown p-0" *ngIf="wallet !=null">
          <app-my-account [wallet]="wallet"></app-my-account>
        </li> -->
        <li class="language-nav" (click)="toggleOffice()">
          <app-office></app-office>
        </li> 
        <li class="language-nav" (click)="toggleOffice()">
          <app-register></app-register>
        </li> 
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
