import { Component, OnInit,Input } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app-settings";
import { WalletModel } from "src/app/shared/model/wallet.model";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  @Input() wallet: WalletModel;
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  tokenSymbol: any;
  public wallet_balance: any = "";

  constructor(
    public router: Router,
    ) {
  }

  copyInputMessage(inputElement) {
    // inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  ngOnInit() {

  }

  logout(){

  }
}
