import { Component, OnInit } from '@angular/core';
import { BlockScannerService } from '../../services/blockscanner.service';
import { environment } from 'src/environments/environment';
import {NetworkScannerModel} from 'src/app/shared/model/networkscanner.model'
@Component({
  selector: 'app-livebar',
  templateUrl: './livebar.component.html',
  styleUrls: ['./livebar.component.scss']
})
export class LivebarComponent {
  constructor(
    //
    public blockScannerService: BlockScannerService,
  ) {}


  
}
