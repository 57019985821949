import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();
  public menuItems: Menu[];
  public companyItems: Menu[];
  public accountItems: Menu[];
  public supportItems: Menu[];
  public faqItems: Menu[];

  constructor(
    private navServices: NavService
  ) { 
    this.navServices.items.subscribe(item_menuItems => {
      this.menuItems = item_menuItems;
    });
    this.navServices.account_items.subscribe(account_menuItems => {
      this.accountItems = account_menuItems;
    });
    this.navServices.support_items.subscribe(support_menuItems => {
      this.supportItems = support_menuItems;
    });
    this.navServices.faq_items.subscribe(faq_menuItems => {
      this.faqItems = faq_menuItems;
    });
    
  }

  ngOnInit(): void {
  }

}
