import { environment } from "./../environments/environment";

export class AppSettings {
    public wsProviderEndpointMainnet = 'wss://bootnodea01.xode.net/n7yoxCmcIrCF6VziCcDmYTwL8R03a/rpc';
    public wsProviderEndpointDevnet = 'wss://humidefi-dev.zeeve.net/para';
    public APIURLHost = environment.APIURLHost;

    public AllURL = [
        {
            url: "nft.xgame.live"
        },
        {
            url: "nft.xgame.co"
        },
        {
            url: "dashboard.xgame.live"
        },
        {
            url: "dashboard.xgame.co"
        },
        {
            url: "astrochibbi.xgame.live"
        },
        {
            url: "astrochibbi.xgame.co"
        },
        {
            url: "xgame.live"
        },
        {
            url: "xgame.co"
        },
        {
            url: "mint.xgame.live"
        },
        {
            url: "mint.xgame.co"
        },
        {
            url: "localhost"
        },
    ]
}
