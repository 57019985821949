<!-- footer start-->
<div
  class="footer-content section-justify-center section-align-center"
  style="padding: 20px;"
>
  <div class="col-xxl-9 col-xl-9 col-lg-10 col-md-10 col-sm-11 col-xs-12">
    <div class="row">
      <div class="container-fluid">
        <div class="row" style="justify-content: center">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div style="display: flex; gap: 1rem; flex-direction: row">
              <div class="col-xxl-6 xol-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <a href="https://www.xgame.live/">
                  <img
                    class="img-fluid for-light image-logo"
                    src="assets/logos/royal_race_logo.webp"
                    alt=""
                  />
                  <img
                    class="img-fluid for-dark image-logo"
                    src="assets/logos/royal_race_logo.webp"
                    alt=""
                  />
                </a>
              </div>
              <div class="col-xxl-6 xol-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div>© 2024 Royal Race. 
                  All Rights Reserved.</div>
                <div>+420 772 438 450</div>
              </div>
              <!-- <a [href]="'mailto:' + appSettings.Email" class="p-b-20">{{
                appSettings.Email
              }}</a> -->
            </div>
          </div>
          <!-- Menu -->
          <div
            class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 p-b-20"
          >
            <div class="row icon-lists">
              <div class="col-sm-9 col-md-9 col-xl-9" class="sub-header p-b-20">
                <b
                  ><span>Company</span></b
                >
              </div>
              <div
                class="col-sm-9 col-md-9 col-xl-9"
                *ngFor="let menu of menuItems; let first = first"
                (click)="redirectTo(menu.path)"
              >
                <span>{{ menu.title }}</span>
              </div>
            </div>
          </div>
          <!-- Account -->
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 p-b-20">
            <div class="row icon-lists">
              <div class="col-sm-9 col-md-9 col-xl-9" class="sub-header p-b-20">
                <b
                  ><span>{{'Account'}}</span></b
                >
              </div>
              <div
                class="col-sm-9 col-md-9 col-xl-9"
                *ngFor="let menu of accountItems; let first = first"
                (click)="redirectTo(menu.path)"
              >
                <span>{{menu.title | translate}}</span>
              </div>
            </div>
          </div>
          <!-- Support -->
          <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 p-b-20">
            <div class="row icon-lists">
              <div class="col-sm-9 col-md-9 col-xl-9" class="sub-header p-b-20">
                <b
                  ><span>{{'Support'}}</span></b
                >
              </div>
              <div
                class="col-sm-9 col-md-9 col-xl-9"
                *ngFor="let menu of supportItems; let first = first"
                (click)="redirectTo(menu.path)"
              >
                <span>{{menu.title | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
<!-- <div class="column" style="gap: 0px">
  <hr class="m-t-15" />
  <div class="col-sm-12 col-md-12 col-xl-12 footer-copyright text-center">
    <p class="m-b-15 paragraph" style="color: white !important">
      {{'Copyright' | translate}} {{ today | date : "y" }} © XGame by NexGen
    </p>
  </div>
</div> -->